import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RouteListing from "../components/routeListing"

import Markdoc from '@markdoc/markdoc';

const IndexPage = ({ pageContext }) => {

    const { name, type, description, properties, routes } = pageContext;

    const ast = Markdoc.parse(description);
    const content = Markdoc.transform(ast, /* config */);

    content.children = content.children.filter((o, i) => {

        if (o.name == "h1") return false;
        return true;

    });

    const html = Markdoc.renderers.html(content);

    return <Layout>
        <section class="mt-32" id="general">
            <div class="mx-auto max-w-screen-xl px-4 py-4 sm:px-6">
                <div
                    class="grid grid-cols-1 gap-y-8 py-8"
                >
                    <div class="mx-auto w-full h-full max-w-lg flex align-top flex-col">
                        <h1 class="text-3xl font-bold sm:text-3xl flex flex-row items-center gap-2">
                            {name}
                            <span className="block bg-slate-100 text-slate-800 rounded border text-sm content-center px-1 py w-min h-min">{type}</span>
                        </h1>
                        <p class="text-gray-600 text-left" dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                </div>
            </div>
        </section>
        <section id="attributes">
            <div class="mx-auto max-w-screen-xl px-4 sm:px-6">
                <div
                    class="grid grid-cols-1 gap-y-8 py-8"
                >
                    <div class="mx-auto w-full max-w-lg h-full lg:text-left flex align-top flex-col">
                        <h2 class="text-2xl font-bold sm:text-2xl">
                            Attributes
                        </h2>
                        <p class="text-gray-600 text-left mb-4">
                            Below are all of the attributes associated with the {name} model. In total, there are {Object.keys(properties).length}.
                        </p>
                        <table className="table w-full bg-slate-50 border border-collapse">
                            <tr>
                                <th className="w-1/3 p-4 border bg-slate-100">Name</th>
                                <th className="w-1/3 p-4 border bg-slate-100">Type</th>
                                <th className="w-1/3 p-4 border bg-slate-100">Example</th>
                            </tr>
                            {
                                Object.keys(properties).map(i => {

                                    const prop = properties[i]

                                    return <tr>
                                        <td className="p-4 border">{i}</td>
                                        <td className="p-4 border">{prop.format ? `${prop.type} - ${prop.format}` : prop.type}</td>
                                        <td className="p-4 border">{JSON.stringify(prop.example)}</td>
                                    </tr>

                                })
                            }
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <section id="routes">
            <div class="mx-auto max-w-screen-xl px-4 sm:px-6">
                <div
                    class="grid grid-cols-1 gap-y-8 py-8"
                >
                    <div class="mx-auto w-full max-w-lg h-full lg:text-left flex align-top flex-col">
                        <h2 class="text-2xl font-bold sm:text-2xl">
                            Routes
                        </h2>
                        <p class="text-gray-600 text-left mb-4">
                            Below are all of the {routes.length} routes connected to the {name} model.
                        </p>
                        <div class="grid grid-cols-2 gap-4">
                            {
                                routes.map((item) => RouteListing({ ...item, href: `/products/${item.slug}` }))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="h-50 w-full p-16"></section>
    </Layout>
}

export const Head = ({ pageContext }) => <Seo title={pageContext.name} children={<>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": `What is Irishman ${""}?`,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": ""
                }
            }]
        })
    }} />
</>} />

export default IndexPage
